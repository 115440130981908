// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
@use "@ng-matero/extensions" as mtx;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$web-primary: mat.define-palette(mat.$green-palette);
$web-accent: mat.define-palette(mat.$green-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $web-warn: mat.define-palette(mat.$red-palette);

$web-typography: mat.define-typography-config(
  $font-family: "Cairo"
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$web-theme: mat.define-light-theme(
  (
    color: (
      primary: $web-primary,
      accent: $web-accent,
      // warn: $web-warn
    ),
    typography: $web-typography
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($web-theme);
@include mtx.all-component-themes($web-theme);

:root {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.mat-mdc-snack-bar-container {
  .mdc-snackbar__label {
    color: black !important;
  }

  .mdc-snackbar__surface {
    background-color: white !important;
  }
}

.drag-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.event-sidebar .mat-expansion-panel-spacing {
  margin: 0;
}

.mat-step-icon {
  font-weight: bold;
}

.mat-step-text-label {
  font-weight: bold;
}

.mat-horizontal-content-container {
  overflow: unset !important;
}

.mtx-loader-backdrop {
  z-index: 3 !important;
}

.mtx-loader-main {
  z-index: 4 !important;
}

::ng-deep.cdk-drag-disabled .cdk-drag-handle {
  opacity: 0.5;
}

.mtx-calendar-previous-button svg,
.mtx-calendar-next-button svg {
  transform: rotate(0deg) !important;
}
