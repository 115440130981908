.swiper-button {
  color: #c0bcbc;
  border: 1px solid #c0bcbc;
  font-size: 16px;
  width: 44px;
  height: 44px;
  background: hsla(0, 0%, 100%, 0.75);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.swiper-button:focus {
  box-shadow: none;
}

.swiper-button:hover {
  border-color: var(--bs-primary);
  background-color: var(--bs-primary);
  color: var(--bs-white);
}

.sidebar {
  width: 280px;
  min-height: 600px;

  & .nav-link {
    background-color: transparent;

    &:hover {
      background-color: rgba(#000000, 0.03);

      &:focus {
        background-color: rgba(#000000, 0.1);
      }
    }

    &.active {
      background-color: #c7ebd8;
      color: inherit;
    }
  }

  &.collapsed {
    width: 74px;
  }
}

.dashboard-table {
  table {
    width: 100%;
  }

  thead {
    display: block;
    position: sticky;
    top: 0;
    width: calc(100% - 1em);

    & th:last-child {
      border-left: 0;
    }

    &::after {
      content: "";
      background-color: var(--bs-table-bg);
      width: 1em;
      height: 36px;
      display: block;
      position: absolute;
      left: -1em;
      top: 0px;
    }
  }

  tbody {
    overflow-y: scroll;
    max-height: calc(36px * var(--dashboard-table-rows-count));
    display: block;
  }

  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.stretched-link {
  opacity: 0;
  width: 0;
  height: 0;
  display: block;
  overflow: hidden;
}

.overflow-anywhere {
  overflow-wrap: anywhere;
}

[type="email"],
[type="number"],
[type="tel"],
[type="url"] {
  direction: ltr !important;
}

.vertical-center {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.image-loader {
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;

  div {
    border: 20px solid;
    border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: image-loader 1s linear infinite;
    background-color: var(--bs-primary);
    opacity: 0.75;
  }

  div,
  img {
    grid-column: 1;
    grid-row: 1;
  }

  &:not(.loaded) {
    div {
      display: block;
    }

    img {
      opacity: 0;
    }
  }

  &.loaded {
    div {
      display: none;
    }

    img {
      opacity: 1;
    }
  }
}

@keyframes image-loader {
  0% {
    border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35)
      rgba(255, 255, 255, 0.75);
  }
  33% {
    border-color: rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25)
      rgba(255, 255, 255, 0.35);
  }
  66% {
    border-color: rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.15)
      rgba(255, 255, 255, 0.25);
  }
  100% {
    border-color: rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.75)
      rgba(255, 255, 255, 0.15);
  }
}

.side-image {
  position: fixed;
  width: inherit !important;
}

.card-title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: calc(1.2rem * 3);
}
